import React, { useRef } from 'react'
import { IoClose, IoMenu, } from "react-icons/io5";
import { motion } from "framer-motion"
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { RootCreatorContext } from '../contexts';
import SwitcherComponents from './SwitcherComponents';
const containerVariants = {


    hidden: {
        opacity: 0,
        x: "-12.5vh",
    },
    visible: {
        opacity: 1,
        x: "0",
        // transition: { duration: .5 }
    },
    exit: {
        x: "-2.5vh",
        opacity: 0,
        transition: { ease: 'easeInOut' },
    }
};


const NavBar = () => {
    const [showMobile, setshowMobile] = React.useState(false);
    const creatorContext = React.useContext(RootCreatorContext)


    return (
        <>
            <div className={`w-full p-[2.5rem] max-sm:rounded-t-[0] rounded-t-lg sticky top-0 shadow-2xl shadow-black/10  transition-all z-50 backdrop-blur-sm ${creatorContext?.themeLight ? "text-slate-900" : "text-white"} ${creatorContext?.themeLight ? "bg-white/60" : "bg-black/20"} max-sm:p-4`}>
                <div className="flex justify-between items-center fixed-w sticky top-5 ">
                    <h3 className='text-4xl font-MontBold'>Folio.<span className='text-[#d61d64] text-xl'>JG</span></h3>
                    <motion.div

                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div className="flex items-center justify-end gap-[2rem] max-lg:hidden">
                            <div id='cossi' className='navLinks'><a href="#Hero">Home</a></div>
                            <div id='cossi' className='navLinks'><a href="#About">About</a></div>
                            <div id='cossi' className='navLinks'><a href="#Services">Services</a></div>
                            <div id='cossi' className='navLinks'><a href="#Skills">Skills</a></div>
                            <div id='cossi' className='navLinks'><a href="#Experiences">Experiences</a></div>
                            <div id='cossi' className='navLinks'><a href="#Portfolio">Portfolio</a></div>
                            <div id='cossi' className='navLinks'><a href="#Contacts">Contacts</a></div>
                            <SwitcherComponents namedID='webVSwitcherID' />
                        </div>
                        {/* <div className="ml-5"></div> */}
                        <button
                            onClick={() => setshowMobile(true)}

                            className="p-2 min-[1024px]:hidden">
                            <IoMenu
                                color={creatorContext?.themeLight ? "black" : "white"}
                                size={18}
                            />
                        </button>
                    </motion.div>
                </div>
            </div>

            {
                showMobile &&
                <div className={`sticky top-0 bottom-0 w-full rounded-lg h-screen backdrop-blur-sm ${creatorContext?.themeLight ? "text-slate-900" : "text-white"} ${creatorContext?.themeLight ? "bg-white/60" : "bg-black/20"} z-[1000] flex min-[1024px]:hidden flex-col items-center justify-center overflow-y-scroll`}>

                    <button
                        onClick={() => setshowMobile(false)}

                        className="p-2 mb-[2rem] ">
                        <IoClose
                            color={creatorContext?.themeLight ? "black" : "white"}
                            size={18}
                        />
                    </button>
                    <h3 className='text-4xl font-MontBold'>Folio.<span className='text-[#d61d64] text-xl'>JG</span></h3>

                    <motion.div

                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className='mt-10'
                    >
                        <div className="flex flex-col items-center gap-[2rem]">
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Hero">Home</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#About">About</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Services">Services</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Skills">Skills</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Experiences">Experiences</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Portfolio">Portfolio</a></div>
                            <div id='cossi' className='navLinks'><a
                                onClick={() => setshowMobile(false)} href="#Contacts">Contacts</a></div>

                            {/* <div onClick={() => {
                                if (!creatorContext?.themeLight) {
                                    creatorContext?.setthemeLight(true)
                                } else {
                                    creatorContext?.setthemeLight(false)
                                }
                            }} className={`rounded-full h-6 relative w-12 ${creatorContext?.themeLight ? "bg-[#d61d64]" : "bg-white"}`}>
                                <div id='switchable2' className={`${creatorContext?.themeLight ? "bg-white" : "bg-slate-900"} border-[2px] 
                                ${creatorContext?.themeLight ? "border-slate-900" : "border-[#d61d64]"} flex items-center justify-center p-[.15rem] 
                                transition-all delay-200 duration-500 rounded-full h-8 left-0 -translate-y-1/2 absolute z-10 shadow-lg top-[50%] bottom-0 w-8`}>
                                    {
                                        creatorContext?.themeLight ? <CiLight
                                            color="#d61d64"
                                            size={40}
                                        /> : <MdDarkMode
                                            color="white"
                                            size={40}
                                        />
                                    }
                                </div>
                            </div> */}

                            <SwitcherComponents namedID='mobileVSwitcherID' />
                        </div>
                    </motion.div>
                </div>
            }
        </>
    )
}

export default NavBar