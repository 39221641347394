import React from 'react'
import { RootCreatorContext } from '../contexts'
import { CiLight } from 'react-icons/ci'
import { MdDarkMode } from 'react-icons/md'


const SwitcherComponents = ({ namedID }: { namedID: string }) => {
    const creatorContext = React.useContext(RootCreatorContext)
    const generatedKeyID = namedID;

    React.useEffect(() => {

        let element = document.querySelector(`#${generatedKeyID}`) as HTMLElement;
        // let element2 = document.querySelector("#switchable2") as HTMLElement;

        if (creatorContext?.themeLight) {
            element.style.marginRight = "0"
            element.style.marginLeft = "calc(100% - 2rem)"
            element.style.transform = "transition-y(50%)"


            // if (Boolean(element2?.style)) {
            //     element2.style.marginRight = "0"
            //     element2.style.marginLeft = "calc(100% - 2rem)"
            //     element2.style.transform = "transition-y(50%)"
            // }
        } else {
            element.style.marginLeft = "0"
            element.style.marginRight = "calc(100%)"
            // if (Boolean(element2?.style)) {
            //     element2.style.marginLeft = "0"
            //     element2.style.marginRight = "calc(100%)"
            // }
        }
    }, [creatorContext])

    return (
        <div onClick={() => {
            if (!creatorContext?.themeLight) {
                creatorContext?.setthemeLight(true)
            } else {
                creatorContext?.setthemeLight(false)
            }
        }} className={`rounded-full cursor-pointer h-6 relative w-12 ${creatorContext?.themeLight ? "bg-[#d61d64]" : "bg-white"}`}>
            <div id={generatedKeyID} className={`${creatorContext?.themeLight ? "bg-white" : "bg-slate-900"} border-[2px] 
            ${creatorContext?.themeLight ? "border-slate-900" : "border-[#d61d64]"} flex items-center justify-center p-[.15rem] 
            transition-all delay-200 duration-500 rounded-full h-8 left-0 -translate-y-1/2 absolute z-10 shadow-lg top-[50%] bottom-0 w-8`}>
                {
                    creatorContext?.themeLight ? <CiLight
                        color="#d61d64"
                        size={40}
                    /> : <MdDarkMode
                        color="white"
                        size={40}
                    />
                }
            </div>
        </div>
    )
}

export default SwitcherComponents