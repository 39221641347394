import React, { useEffect } from 'react'
import { motion } from "framer-motion"

const containerVariants = {
    visible: {
        opacity: 0,
        transition: { duration: .5 }
    },
};
const Points = () => {

    return (
        <div id='pointsParent'
            className='sticky flex gap-[3px] flex-col items-center right-0 top-[60%] translate-y-[-60%]  z-40 '>

            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Hero' className='points' />
            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#About' className='points' />

            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Services' className='points' />

            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Skills' className='points' />
            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Experiences' className='points' />

            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Portfolio' className='points' />

            <div className="h-[18px] bg-slate-600 w-[2px]" />
            <motion.a
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                href='#Contacts' className='points' />
        </div>
    )
}

export default Points