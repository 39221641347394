import React from "react"


export interface ValuesSetthemeLightDataTypes {
    themeLight: boolean,
    setthemeLight: React.Dispatch<React.SetStateAction<boolean>>
}
export const RootCreatorContext = React.createContext<ValuesSetthemeLightDataTypes | null>(null)
export const RootCreatorProvider = ({ children }: { children: React.ReactNode }) => {
    const [themeLight, setthemeLight] = React.useState<boolean>(false)

    return <RootCreatorContext.Provider value={{ themeLight, setthemeLight }}>
        {children}
    </RootCreatorContext.Provider>
}