import React, { useEffect, useRef } from 'react'
import { BoxPercentage } from '../CustomStyles/BoxPercentage'
import { motion, useTransform, useViewportScroll, useScroll } from "framer-motion"
import { RootCreatorContext } from '../contexts'

const Experiences = () => {


    const creatorContext = React.useContext(RootCreatorContext)
    // const { scrollYProgress } = useScroll();
    const { scrollYProgress } = useViewportScroll()
    const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);

    React.useEffect(() => {
        function animatedTimelines() {

            let options = {
                root: null,
                rootMargin: "0px",
                // threshold: .4,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target?.parentElement?.classList.add("visible")
                    }
                });
            }, options);

            let contentsLeft = document.querySelectorAll(".container.left .content")
            let contentsRight = document.querySelectorAll(".container.right .content")
            contentsLeft.forEach(er => {
                observer.observe(er);
            })

            contentsRight.forEach(er => {
                observer.observe(er);
            })
        }

        window.addEventListener("scroll", animatedTimelines)

        return () => {
            window.removeEventListener('scroll', animatedTimelines);
        };
    }, [])

    const render_last_v = () => {
        <>
            <div
                className="flex gap-5 items-start max-sm:flex-col">
                <div className="bg-slate-950 p-3 rounded-sm shadow-sm text-[.8rem] font-MontSemiBold text-center">2019 <span className="block">-</span> <span className="block">2020</span></div>
                <div className="flex gap-5 mt-4 px-2">
                    <div
                        style={{ scale }}
                        // style={{ scale }}
                        className="flex items-center flex-col gap-2">
                        <div
                            className='w-[20px] rounded-full bg-[#f31f70] h-[22px] shadow-[#f31f70] shadow-xl' />
                        <div
                            className="h-full bg-slate-600 w-[2px] relative">
                            {/* <div className="absolute inset-0 bg-red-500 w-full h"
                                    style={{
                                        scaleY: (scrollYProgress)
                                    }}
                                /> */}
                        </div>
                    </div>
                    <div className="-mt-4 ml-5 max-sm:ml-0 max-w-[750px]">
                        <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Stage Web Dev</h2>
                        <h3 className='font-MontSemiBold mb-6 text-slate-400'>Hitotec SARL</h3>
                        <p className='leading-[2.1rem] text-[.95rem]'>It is with this company that I started to take my marks in the field of Web Development. I had to get up to speed with the basic technologies to code Web applications.  I got closer to the developers to get in touch with them and familiarize myself with the tools and techniques that the company uses to manage its products.                             </p>
                    </div>
                </div>
            </div>

            <div className="flex gap-5 items-start max-sm:flex-col">
                <div className="bg-slate-950 p-3 rounded-sm shadow-sm font-MontSemiBold text-[.8rem] text-center">2021 <span className='block'>-</span> <span className='block'>2022</span></div>
                <div className="flex gap-5 mt-4 px-2">
                    <div className="flex items-center flex-col gap-2">
                        <div
                            className='w-[20px] rounded-full bg-[#f31f70] h-[22px] shadow-[#f31f70] shadow-xl' />
                        <div className="h-full bg-slate-600 w-[2px]" />
                    </div>
                    <div className="-mt-4 ml-5 max-sm:ml-0 max-w-[750px]">
                        <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Web/Mobile Front-End Developer, Odoo Module Developer</h2>
                        <h3 className='font-MontSemiBold mb-6 text-slate-400'>Institut Cerco Bénin (Sikecodji)</h3>
                        <p className='leading-[2.1rem] text-[.95rem]'>During my internship at Institut cerco I held the position of Mobile and Web Front-End Developer on urgent administration projects for clients. I had to work on Front-End Web/Mobile projects to coordinate with the backend devs for integrations into the app. This time allowed me to familiarize myself with the best practices for delivering systems and applications and also allowed me to get used to the pressure a bit.
                            Also in this time I had to learn Odoo to create custom modules for a new perspective on which the administration was looking to deliver customer functionality.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex gap-5 items-start max-sm:flex-col">
                <div className="bg-slate-950 p-3 rounded-sm shadow-sm font-MontSemiBold text-[.8rem]">2022</div>
                <div className="flex gap-5 mt-4 px-2">
                    <div className="flex items-center flex-col gap-2">
                        <div
                            className='w-[20px] rounded-full bg-[#f31f70] h-[22px] shadow-[#f31f70] shadow-xl' />
                        <div className="h-full bg-slate-600 w-[2px]" />
                    </div>
                    <div className="-mt-4 ml-5 max-sm:ml-0 max-w-[750px]">
                        <h2 className='font-MontSemiBold text-[2rem] mb-3'>Web/Mobile Front-Integration Developer</h2>
                        <h3 className='font-MontSemiBold mb-6 text-slate-400'>Arcadia WEB</h3>
                        <p className='leading-[2.1rem] text-[.95rem]'>
                            With this company I held the position of Web/Mobile Front-Integration Developer and I mainly worked on projects from the Front-End to the integration of features and API services of the company.
                            With this company I discovered another way to approach the Dev in a flexible way by working in remote and in collaboration with other developers and project managers.
                            This also allowed me to update myself on new technologies and agile tools for Dev to deployment.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex gap-5 items-start max-sm:flex-col">
                <div className="bg-slate-950 p-3 rounded-sm shadow-sm font-MontSemiBold text-[.8rem] text-center">2022 <span className="block">-</span> <span className="block">2023</span></div>
                <div className="flex gap-5 mt-4 px-2">
                    <div className="flex items-center flex-col gap-2">
                        <div
                            className='w-[20px] rounded-full bg-[#f31f70] h-[22px] shadow-[#f31f70] shadow-xl' />
                        <div className="h-full bg-slate-600 w-[2px]" />
                    </div>
                    <div className="-mt-4 ml-5 max-sm:ml-0 max-w-[750px]">
                        <h2 className='font-MontSemiBold text-[2rem] mb-3'>Freelancing</h2>
                        {/* <h3 className='font-MontSemiBold mb-6 text-slate-400'>Arcadia WEB</h3> */}
                        <p className='leading-[2.1rem] text-[.95rem]'>
                            I work until now as a freelancer on flexible customer projects. I discover new technologies more in demand on the Dev market allowing me to update myself all the time.
                        </p>
                    </div>
                </div>
            </div>
        </>
    }

    // const boxRef = useRef(null);

    // useEffect(() => {
    //     function handleScroll() {
    //         console.log('Box Scrolled');
    //         // Do something when the scroll event occurs on the box
    //     }

    //     console.log("Iroko")

    //     const boxElement = boxRef.current;
    //     boxElement.addEventListener('scroll', handleScroll);

    //     return () => {
    //         boxElement.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <div className="w-full pb-8 overflow-x-hidden">
            <section
                className="py-5 max-sm:px-0 w-full mx-auto flex flex-col items-start  relative" id="Experiences"
            >
                <div id="nSc" className="w-full max-w-[90vw] overflow-scroll mb-[4rem]">
                    <h2 className="text-[3.5rem] w-fit mx-auto font-MontBold max-lg:text-[3rem] text-[#d61d64] text-ellipsis">Experiences</h2>
                </div>
                <div class="timeline ">
                    <div class="container bg-red-400 left ">
                        <div className={`content overflow-hidden relative after:absolute ${creatorContext.themeLight ? "hover:text-white" : "hover:text-black"} after:-z-10  z-10 ${creatorContext.themeLight ? "after:bg-slate-900" : "after:bg-white"}
                                after:bottom-0 after:right-0 after:h-[2px] after:w-[2px] after:invisible hover:after:visible hover:after:h-full hover:after:w-full after:transition-all transition-all shadow-2xl shadow-black/10  ${creatorContext.themeLight ? "bg-white/40" : "bg-black/40"}`}>
                            <div className="mb-6 font-MontSemiBold text-[.8rem] ">2019 - 2020</div>
                            <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Stage Web Dev</h2>
                            <h3 className='font-MontSemiBold mb-6 text-slate-400'>Hitotec SARL</h3>
                            <p className='leading-[2rem] text-[.90rem]'>It is with this company that I started to take my marks in the field of Web Development. I had to get up to speed with the basic technologies to code Web applications.  I got closer to the developers to get in touch with them and familiarize myself with the tools and techniques that the company uses to manage its products.</p>
                        </div>
                    </div>
                    <div class="container right ">
                        <div className={`content overflow-hidden relative after:absolute ${creatorContext.themeLight ? "hover:text-white" : "hover:text-black"} after:-z-10  z-10 ${creatorContext.themeLight ? "after:bg-slate-900" : "after:bg-white"}
                                after:bottom-0 after:right-0 after:h-[2px] after:w-[2px] after:invisible hover:after:visible hover:after:h-full hover:after:w-full after:transition-all transition-all shadow-2xl shadow-black/10  ${creatorContext.themeLight ? "bg-white/40" : "bg-black/40"}`}>
                            <div className="mb-6 font-MontSemiBold text-[.8rem] ">2021 - 2022</div>
                            <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Web/Mobile Front-End Developer, Odoo Module Developer</h2>
                            <h3 className='font-MontSemiBold mb-6 text-slate-400'>Institut Cerco Bénin (Sikecodji)</h3>
                            <p className='leading-[2rem] text-[.90rem]'>During my internship at Institut cerco I held the position of Mobile and Web Front-End Developer on urgent administration projects for clients. I had to work on Front-End Web/Mobile projects to coordinate with the backend devs for integrations into the app. This time allowed me to familiarize myself with the best practices for delivering systems and applications and also allowed me to get used to the pressure a bit.
                                Also in this time I had to learn Odoo to create custom modules for a new perspective on which the administration was looking to deliver customer functionality.
                            </p>
                        </div>
                    </div>
                    <div class="container left ">
                        <div className={`content overflow-hidden relative after:absolute ${creatorContext.themeLight ? "hover:text-white" : "hover:text-black"} after:-z-10  z-10 ${creatorContext.themeLight ? "after:bg-slate-900" : "after:bg-white"}
                                after:bottom-0 after:right-0 after:h-[2px] after:w-[2px] after:invisible hover:after:visible hover:after:h-full hover:after:w-full after:transition-all transition-all shadow-2xl shadow-black/10  ${creatorContext.themeLight ? "bg-white/40" : "bg-black/40"}`}>
                            <div className="mb-6 font-MontSemiBold text-[.8rem] ">2022</div>
                            <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Web/Mobile Front-Integration Developer</h2>
                            <h3 className='font-MontSemiBold mb-6 text-slate-400'>Arcadia WEB</h3>
                            <p className='leading-[2rem] text-[.90rem]'>
                                With this company I held the position of Web/Mobile Front-Integration Developer and I mainly worked on projects from the Front-End to the integration of features and API services of the company.
                                With this company I discovered another way to approach the Dev in a flexible way by working in remote and in collaboration with other developers and project managers.
                                This also allowed me to update myself on new technologies and agile tools for Dev to deployment.
                            </p>
                        </div>
                    </div>
                    <div class="container right">
                        <div className={`content overflow-hidden relative after:absolute ${creatorContext.themeLight ? "hover:text-white" : "hover:text-black"} after:-z-10  z-10 ${creatorContext.themeLight ? "after:bg-slate-900" : "after:bg-white"}
                                after:bottom-0 after:right-0 after:h-[2px] after:w-[2px] after:invisible hover:after:visible hover:after:h-full hover:after:w-full after:transition-all transition-all shadow-2xl shadow-black/10  ${creatorContext.themeLight ? "bg-white/40" : "bg-black/40"}`}>
                            <div className="mb-6 font-MontSemiBold text-[.8rem] ">2022 - 2023</div>
                            <h2 className='font-MontSemiBold text-[1.3rem] mb-3'>Freelancing</h2>
                            {/* <h3 className='font-MontSemiBold mb-6 text-slate-400'>Arcadia WEB</h3> */}
                            <p className='leading-[2rem] text-[.90rem]'>
                                I work until now as a freelancer on flexible customer projects. I discover new technologies more in demand on the Dev market allowing me to update myself all the time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Experiences