import React from 'react'
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaGithub, FaCoins, FaGlobeAfrica } from "react-icons/fa";
import { SiFreelancer } from "react-icons/si";
import { HiDeviceMobile } from "react-icons/hi";
import { BsBuildingsFill } from "react-icons/bs";
import { RootCreatorContext } from '../contexts';

const Services = () => {

    const creatorContext = React.useContext(RootCreatorContext)
    const services = [
        // {
        //     icon: <FaGithub
        //         size={42.5}
        //     />,
        //     name: "Git Version Control",
        //     text: "I use Git version control to manage the version of projects and hava ability to fix somes issues in development and serve projects for deployment"
        // },
        {
            icon: <FaCoins
                size={42.5}
            />,
            name: "Back-End Development",
            text: "For this service I use Back-End Technologies like python and Django or JS with Node.js to implement backend Structures for projects and serve it like APIs to Front-End Development or Clients in somes cases."
        },
        {
            icon: <FaGlobeAfrica
                size={42.5}
            />,
            name: "Web Development",
            text: "There I use the front-end frameworks like Django or React JS with typescript and also Next JS with Typescript also for other cases Projects requirements."
        },
        {
            icon: <HiDeviceMobile
                size={42.5}
            />,
            name: "Mobile Development",
            text: "There I use the front-end Mobile frameworks like React-Native with bare CLI tools or Expo to serves Projects for Mobiles plateformes. I use also Flutter in cases."
        },
        {
            icon: <BsBuildingsFill
                size={42.5}
            />,
            name: "ERP Development",
            text: "I use the major ERP tool like Odoo to build applications and services and many others stuff for others privates structures need."
        },
        {
            icon: <SiFreelancer
                size={42.5}
            />,
            name: "Freelancing",
            text: "With times I do also Frelancing for flexible small and large projects."
        },
    ]

    React.useEffect(() => {
        function animatedTimelines() {

            let options = {
                root: null,
                rootMargin: "0px",
                // threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visibleB")
                    }
                });
            }, options);

            let animateAbout = document.querySelector(".toxicServices")
            observer.observe(animateAbout);
        }

        window.addEventListener("scroll", animatedTimelines)

        return () => {
            window.removeEventListener('scroll', animatedTimelines);
        };
    }, [])

    return (
        <section
            className="mt-10 py-5 w-full flex transition-all delay-100 duration-700 toxicServices invisible overflow-hidden -translate-x-[15rem] scale-75 flex-col items-start relative" id="Services"
        >
            <h2 className="text-[3.5rem] relative font-MontBold max-lg:text-[3rem] text-[#d61d64] after:left-0 after:absolute
                    after:bg-gradient-to-r after:from-[#d61d64] after:to-fuchsia-500 after:w-1/2 after:h-[2px] py-5 mx-auto
                    after:bottom-0">Services</h2>

            <div className="flex items-center justify-center gap-[2.5rem] gap-y-[4rem] w-full mt-[2rem] py-[3rem] flex-wrap">
                {
                    services.map(i => {
                        return (
                            <div className={`rounded-md shadow-2xl shadow-black/10 ${!creatorContext.themeLight && "border-[.5px]"} overflow-hidden
                            relative after:absolute after:-z-10 z-10 ${creatorContext.themeLight ? "after:bg-slate-900 hover:text-white" : "after:bg-white hover:text-black"} transition-all max-[500px]:hover:scale-[1] hover:scale-[1.1] hover:-translate-y-5
                            after:bottom-0 after:left-0 after:w-full after:h-0 hover:after:h-full after:transition-all                            
                            ${!creatorContext.themeLight && "border-slate-800/30"} ${creatorContext.themeLight && "bg-white/40"} max-h p-6 pt-[4.5rem] max-w-[320px]`}>
                                <div className='text-[#d61d64] '>
                                    {
                                        i.icon
                                    }
                                </div>
                                <div className="mt-5">
                                    <h3 className="font-MontSemiBold text-[#d61d64]">
                                        {i.name}
                                    </h3>
                                    <p className='text-[.85rem] mt-5 leading-[2.1rem]'>{i?.text}</p>

                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </section>
    )
}

export default Services