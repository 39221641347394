import React from 'react'
import { BoxPercentage } from '../CustomStyles/BoxPercentage'
import { RootCreatorContext } from '../contexts'

const About = () => {

    const creatorContext = React.useContext(RootCreatorContext)

    React.useEffect(() => {
        function animatedTimelines() {

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visibleA")
                    }
                });
            }, options);

            let animateAbout = document.querySelector(".toxicAbout")
            observer.observe(animateAbout);
        }

        window.addEventListener("scroll", animatedTimelines)

        return () => {
            window.removeEventListener('scroll', animatedTimelines);
        };
    }, [])


    return (
        <section
            className="mt-10 toxicAbout p-5 max-sm:px-0 transition-all delay-100 duration-700 px-4 w-full flex flex-col items-start invisible -translate-y-[5rem] relative" id="About"
        >
            <div className={`shadow-2xl shadow-black/20  ${creatorContext.themeLight ? "animate_box3" : "animate_box2"} transition-all border-[1px] border-slate-800/40 p-10 max-sm:px-2 rounded-md`}>
                <h2 className="text-[3rem] font-MontBold max-lg:text-[2.5rem] w-full text-center text-[#d61d64]">About Me</h2>
                <div className="mt-10">
                    <div className={`flex items-start flex-col justify-center max-md:items-center max-sm:flex-col max-sm:items-start ${creatorContext.themeLight && "text-white"}`}>
                        <p className="leading-[2.1rem] mt-1 w-full text-center">I am a developer with a passion for all things IT and all emerging technologies to create custom systems that fit the clients needs.
                            I have worked on projects ranging from<span className="mx-2 font-MontSemiBold text-[#d61d64]">
                                Web (Front-End to Back-End) to Mobile,
                            </span>which allowed me to gain experience and really adapt to the needs of the market.
                            I have also developed an ability to understand the needs of the end user and develop solutions that meet those needs.
                            I am also good at working in teams, collaborating with other developers, designers and project managers to create innovative and quality solutions.
                            As a passionate and experienced developer, I am ready to take on new challenges and explore new technologies to deliver innovative and powerful solutions to clients.</p>
                    </div>
                </div>
                <div className="flex justify-center gap-[4rem] max-sm:gap-3 flex-wrap items-center mt-10">
                    <div className="flex items-center justify-center gap-4 max-sm:gap-2 flex-wrap">
                        <h2 className="text-[4rem] text-[#d61d64] font-MontSemiBold">
                            5
                        </h2>
                        <div className="">
                            <p className='text-slate-400 text-[.9rem]'>Years of <span className="block">
                                Experience</span>
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center justify-center gap-4 max-sm:gap-2 flex-wrap">
                        <h2 className="text-[4rem] text-[#d61d64] font-MontSemiBold">
                            25+
                        </h2>
                        <div className="">
                            <p className='text-slate-400 text-[.9rem] max-sm:text-center'>projects of <span className="block">
                                Clients Served</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About