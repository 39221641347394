
import styled from 'styled-components';
export const BoxPercentage = styled.div`
  position: absolute;
  top: 50%;
  right:0;
  display: flex;
  align-items: center;
  writing-mode: vertical-lr;
  transform: translateY(-50%) !important;
`;
