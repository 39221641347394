import React, { CSSProperties } from 'react'
import Hero from './components/Hero'
import "./App.scss"
import NavBar from './components/NavBar'
import Services from './components/Services'
import About from './components/About'
import Skills from './components/Skills'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'
import Points from './components/Points'
import Footer from './components/Footer'
import Experiences from './components/Experiences'


// Styles must use direct files imports
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import useScript from './hooks/useLoaderScript'

import { motion } from "framer-motion"
import { ClipLoader, ScaleLoader } from 'react-spinners'
import { RootCreatorContext } from './contexts'

const override = {
  display: "inline",
  margin: "0",
};

const containerVariants = {


  hidden: {
    opacity: 0,
    x: "-5px",
    y: "-5px",
    // scale: .9,
  },
  visible: {
    opacity: 1,
    x: "0",
    y: "0",
    // scale: 1,
    transition: { duration: .5 }
  },
  exit: {
    x: "-2.5vh",
    opacity: 0,
    transition: { ease: 'easeInOut' },
  }
};
const App = () => {
  const creatorContext = React.useContext(RootCreatorContext)

  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let sections = document.querySelectorAll("section")
    let navLinks = document.querySelectorAll("#cossi")
    let points = document.querySelectorAll(".points")

    const load = () => {
      sections.forEach(sec => {
        let top = window.scrollY;
        let offset = sec.offsetTop
        let height = sec.offsetHeight
        let id = sec.getAttribute("id")

        if (top >= offset && top < offset + height) {
          navLinks.forEach((link) => {
            link.classList.remove("active")
            let re = document.querySelector("#cossi a[href*=" + id + "]")?.parentElement?.classList?.add("active")
          })
          points.forEach((link) => {
            link.classList.remove("active")
            let re = document.querySelector("#pointsParent a[href*=" + id + "]")?.classList?.add("active")
          })

        }
      })
    }


    window.addEventListener("scroll", load)

    return () => {
      window.removeEventListener('scroll', load);
    };
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 300)
  }, [])

  return (
    <div className='min-h-screen w-full p-[2rem] max-md:p-[1rem] max-sm:p-[0] transition-all relative'>
      {/* {
        loaded ? 
          : <div className='flex w-full h-[92.5vh] items-end justify-end'>
            <h3 className="font-MontSemiBold text-[1.2rem]">Loading...</h3>
          </div>
      } */}

      {
        !loaded && <p className="-z-10 w-full h-[98vh] max-sm:h-[90vh] max-sm:p-5  max-sm:text-[1rem]  
          p-10 text-[1.5rem] flex items-end justify-end fixed inset-0">Loading... <ScaleLoader

            color={"white"}
            loading={!loaded}
            // cssOverride={override}
            // size={5}
            height={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          /></p>
      }

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate={loaded ? "visible" : "hidden"}
        exit="exit"

        className={` ${creatorContext.themeLight ? "bg-[#f5f5f5] text-slate-900" : "bg-slate-900 text-white"} transition-all min-h-screen w-full max-sm:rounded-[0] rounded-lg shadow-2xl shadow-black/10 relative`}>
        <NavBar />
        <div className="p-[2.5rem] max-sm:p-3">
          <div className="fixed-w max-sm:p-3 p-[2rem] px-0 ">
            <div className="flex items-start min-h-screen relative">
              <div className="w-full">
                <Hero />
                <About />
                <Services />
                <Skills />
                <Experiences />
                <Portfolio />
                {/* <Contact /> */}
              </div>
              <Points />
            </div>
            <div className="flex flex-col w-full items-center justify-center p-4 max-sm:px-1 mt-[12rem]">
              <h3 className="text-[3.5rem] max-md:text-[2.5rem] text-center font-MontBold animate_text">Thanks for scrolling and viewing these details.</h3>
            </div>
          </div>
        </div>

        <Footer />
      </motion.div>
    </div>
  )
}

export default App