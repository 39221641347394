import React, { useRef } from 'react'
import { IoPersonCircle, IoDocumentAttachOutline, IoLogoWhatsapp } from "react-icons/io5";
import Typical from 'react-typical'
import { ImLinkedin } from "react-icons/im";
import { RiFacebookCircleLine } from "react-icons/ri";

import { gsap } from 'gsap'
import Typed from 'react-typed';
import { motion } from "framer-motion"

import PDFCV from "../imgs/Avodagbe_Jean_Gontran_CV.pdf"
import { RootCreatorContext } from '../contexts';

import ME from "../imgs/mee.jpeg"

const containerVariants = {


    hidden: {
        opacity: 0,
        x: "12.5vh",
    },
    visible: {
        opacity: 1,
        x: "0",
        // transition: { duration: .5 }
    },
    exit: {
        x: "2.5vh",
        opacity: 0,
        transition: { ease: 'easeInOut' },
    }
};

const Hero = () => {
    const creatorContext = React.useContext(RootCreatorContext)

    const ref = useRef(null);
    React.useEffect(() => {
        let animeParent = document.querySelector("#magicParent")

        animeParent.addEventListener("mouseenter", (e) => {
            let animeParentSou = document.querySelector("#magicParent")
            gsap.to("#magic", { opacity: 1, scale: 2, })

            let xTo = gsap.quickTo("#magic", "x", { duration: 0.6, ease: "power3" }),
                yTo = gsap.quickTo("#magic", "y", { duration: 1, ease: "power3" });

            animeParentSou?.addEventListener("mousemove", ev => {
                // xTo(e.pageX);
                // yTo(e.pageY);
                var rect = ref?.current?.getBoundingClientRect() || { left: 0, top: 0 }
                const e = ev;

                xTo((e?.clientX - (rect.left)) / 1.05);
                yTo((e?.clientY - rect.top) / 1.05);
            });
        })
        animeParent.addEventListener("mouseleave", () => {
            console.log("Leaving animation")
            gsap.to("#magic", { scale: 1, opacity: 0, });
        })

    }, [])
    return (
        <section className='h-[90vh] pb-5 w-full max-h-[600px]
        flex flex-col items-start justify-start pt-5 relative'
            id="Hero">
            <div className="pr-[2rem] relative w-full">

                <div className="z-50 flex w-fit mb-[2rem] max-sm:hidden gap-[1rem] items-center">
                    <a href='https://web.facebook.com/jean.avodagbe.96?mibextid=LQQJ4d&_rdc=1&_rdr' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <RiFacebookCircleLine
                            size={18}
                        />
                    </a>
                    <a href='https://www.linkedin.com/in/jean-gontran-avodagb%C3%A9-0418661a5' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <ImLinkedin
                            size={18}
                        />
                    </a>
                    <a href='https://wa.me/53239294?text=Hey%20there.%3F' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <IoLogoWhatsapp
                            size={18}
                        />
                    </a>
                </div>
                <div id="magicParent" ref={ref} className="flex relative gap-2 items-center justify-between w-full">
                    <div id='magic' className={`animate_box shadow-2xl opacity-0 absolute top-0 left-0 z-10 h-[3.5rem] min-[1024px]:h-[4rem] w-[3.5rem] min-[1024px]:w-[4rem] rounded-full`}>
                        <a href='https://www.linkedin.com/in/jean-gontran-avodagb%C3%A9-0418661a5'
                            target='_blank'
                            className={`shadow-2xl absolute w-full h-full bg-cover -z-40 top-0 -translate-x-[8px] -translate-y-[4px] rounded-[5rem] border-[.25px] ${creatorContext.themeLight ? "border-white/40" : "border-slate-900/40"}`}>
                            <img src={ME} alt="My picture" className='rounded-full' />
                        </a>
                    </div>
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="w-full"
                    >

                        <h4 className={`${creatorContext.themeLight ? "text-slate-700" : "text-slate-500"} text-[1.35rem] mb-8 font-MontRegular`}>Hola Welcome 👋.</h4>

                        <div className="flex items-center ">

                            <h2 className="text-[5rem] font-MontBold overflow-y-hidden
                            max-[1175px]:text-[4rem] max-[1175px]:leading-[5rem] 
                            max-[990px]:text-[3.2rem] max-[990px]:leading-[4.8rem] 
                            max-md:text-[2.9rem] max-md:leading-[4rem]
                            leading-[6rem] max-[500px]:px-0 animate_text">I'm
                                <p>
                                    <Typed
                                        strings={[
                                            'Full-Stack Developer',
                                            'Front-End Developer',
                                            'Back-End Developer',
                                            'Mobile Developer',
                                            'Odoo Developer',
                                            'UI/UX Designer',
                                            'Devops Junior',
                                        ]}
                                        typeSpeed={100}
                                        backSpeed={100}
                                        loop />
                                </p>
                                {/* <Typical
                                    steps={[
                                        'Full-Stack Developer', 4000,
                                        'Front-End Developer', 4000,
                                        'Back-End Developer', 4000,
                                        'ERP Developer', 4000,
                                        'Mobile Developer', 4000,
                                    ]}
                                    loop={Infinity}
                                    cl
                                    wrapper="p"
                                /> */}
                            </h2>

                            {/* <h2 className='text-[2.1rem] w-full leading-[3rem] font-MontBold animate_text min-[510px]:hidden'>I'm Full-Stack Developer</h2> */}
                        </div>
                    </motion.div>

                </div>

                <div className="z-50 flex w-fit flex-wrap mt-[2rem] min-[650px]:hidden gap-[1rem] items-center">
                    <a href='https://web.facebook.com/jean.avodagbe.96?mibextid=LQQJ4d&_rdc=1&_rdr' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <RiFacebookCircleLine
                            size={18}
                        />
                    </a>
                    <a href='https://www.linkedin.com/in/jean-gontran-avodagb%C3%A9-0418661a5' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <ImLinkedin
                            size={18}
                        />
                    </a>
                    <a href='https://wa.me/53239294?text=Hey%20there.%3F' target={"_blank"} className={`active:scale-[.8] text-[#d61d64]  
                        backdrop-blur-sm ${creatorContext.themeLight ? "bg-slate-900/10" : "bg-black/30"} ${creatorContext.themeLight && "bg-slate-900/10"} rounded-full p-[.7rem] shadow-xl shadow-black/10 transition-all`}>
                        <IoLogoWhatsapp
                            size={18}
                        />
                    </a>
                </div>

                <div className="w-full mt-5">

                    <p className={`text-[.85rem] ${creatorContext.themeLight ? "text-slate-800" : "text-slate-400"} w-full leading-[1.6rem] mt-[1rem]
                            min-[800px]:max-w-[500px]`}>I'm <span className={`font-MontSemiBold ${creatorContext.themeLight ? "text-slate-400" : "text-slate-200"} mx-1 underline`}>AVODAGBE Jean-Gontran</span> Full-Stack Web/Mobile Developper. You can contact me for more info, discuss or you can also download my CV directly.</p>

                    <div className="flex items-center gap-5 mt-[2rem] flex-wrap ">
                        <a download href={PDFCV} className="animate_box py-2 flex items-center border-2 border-transparent gap-4 flex-wrap whitespace-nowrap justify-center
                                active:scale-90 px-[4rem] max-sm:px-5 max-[400px]:px-2 text-[.95rem] font-MontRegular rounded-sm shadow-lg float-left">
                            Downlaod My CV <IoDocumentAttachOutline
                                size={18}
                            />
                        </a>
                        <a href='#Contacts'
                            className={`py-2 transition-all ${creatorContext.themeLight ? "hover:bg-slate-900 hover:text-white" : "hover:bg-white hover:text-black"} bg-transparent  border-[1px] border-[#d61d64] gap-4 flex items-center
                            active:scale-90 px-[4rem] max-sm:px-5 max-[400px]:px-2 text-[.95rem] text-[#d61d64] font-MontRegular rounded-sm shadow-lg float-left`}>
                            Contact Me <IoPersonCircle
                                size={18}
                            />
                        </a>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default Hero
