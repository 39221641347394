
import PYTHON from "../imgs/python.png"
import DOCKER from "../imgs/docker.png"
import REACTJS from "../imgs/science.png"
import REACTNATIVE from "../imgs/atom.png"
import JS from "../imgs/js.png"
import NEXT from "../imgs/next.png"
import NODE from "../imgs/node.png"
import MONGO from "../imgs/mongo.jpg"
import SQL from "../imgs/sql.png"
import HTML_CSS from "../imgs/html_css.png"
import SASS from "../imgs/sass.png"
import DJANGO from "../imgs/django.png"
import FLUTTER from "../imgs/flutter.png"
import UBUNTU from "../imgs/ubuntu.png"
import ODOO from "../imgs/odoo.png"
import FIREBASE from "../imgs/firebase.png"
import GIT from "../imgs/git.png"
import JEST from "../imgs/jest-js-icon.png"
import SANITY from "../imgs/sanity.png"
import TAILWIND from "../imgs/tailwindcss.png"
import RADIX from "../imgs/radix.png"
import POSTGRESSQL from "../imgs/postgress.jpg"
import SPLINE from "../imgs/spline.jpg"
import THREEJS from "../imgs/three.png"
import JIRA from "../imgs/jira.jpg"
import GSAP from "../imgs/gsap.png"
import FIGMA from "../imgs/figma.png"
import ANGULAR from "../imgs/angular.png"
import WORDPRESS from "../imgs/wordpress.png"
import TERRAFORM from "../imgs/terraform.png"
import AZURE_AKS from "../imgs/azure-aks.jpeg"
import AZURE_DEVOPS from "../imgs/azure-devops.png"

import React from "react"
import { RootCreatorContext } from "../contexts"

const Skills = () => {
    const creatorContext = React.useContext(RootCreatorContext)

    const skills = [
        {
            icon: JS,
            name: "Javscript"
        },
        {
            icon: JEST,
            name: "Jest"
        },
        {
            icon: REACTJS,
            name: "React (JS-TS)"
        },
        {
            icon: REACTNATIVE,
            name: "React-native (JS-TS)"
        },
        {
            icon: NEXT,
            name: "Next (JS-TS)"
        },
        {
            icon: NODE,
            name: "Node JS"
        },
        {
            icon: MONGO,
            name: "Mongo DB"
        },
        {
            icon: SQL,
            name: "SQL"
        },
        // {
        //     icon: ANGULAR,
        //     name: "Angular"
        // },
        // {
        //     icon: WORDPRESS,
        //     name: "Wordpress"
        // },
        {
            icon: POSTGRESSQL,
            name: "PostgreSQL"
        },
        {
            icon: HTML_CSS,
            name: "HTML-CSS"
        },
        {
            icon: GSAP,
            name: "Gsap"
        },
        {
            icon: TAILWIND,
            name: "Tailwind"
        },
        {
            icon: SASS,
            name: "Sass"
        },
        {
            icon: PYTHON,
            name: "Python"
        },
        {
            icon: DJANGO,
            name: "Django"
        },
        {
            icon: GIT,
            name: "Git"
        },
        {
            icon: FIREBASE,
            name: "Firebase"
        },
        // {
        //     icon: FLUTTER,
        //     name: "Flutter"
        // },
        {
            icon: FIGMA,
            name: "Figma"
        },
        // {
        //     icon: UBUNTU,
        //     name: "Ubuntu"
        // },
        {
            icon: ODOO,
            name: "Odoo15"
        },
        {
            icon: DOCKER,
            name: "Docker"
        },
        {
            icon: JIRA,
            name: "Jira"
        },
        {
            icon: SANITY,
            name: "Sanity"
        },
        // {
        //     icon: RADIX,
        //     name: "Radix"
        // },
        {
            icon: SPLINE,
            name: "Spline"
        },
        {
            icon: THREEJS,
            name: "Three JS"
        },
        {
            icon: TERRAFORM,
            name: "Terraform"
        },
        {
            icon: AZURE_AKS,
            name: "Azure AKS"
        },
        {
            icon: AZURE_DEVOPS,
            name: "Azure Devops"
        },
    ]

    React.useEffect(() => {
        function animatedTimelines() {

            let options = {
                root: null,
                rootMargin: "0px",
                // threshold: .5,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visibleB")
                    }
                });
            }, options);

            let animateAbout = document.querySelector(".toxicSkills")
            observer.observe(animateAbout);
        }

        window.addEventListener("scroll", animatedTimelines)

        return () => {
            window.removeEventListener('scroll', animatedTimelines);
        };
    }, [])

    return (
        <div className="w-full overflow-x-hidden">

            <section
                className="py-5 w-full transition-all delay-100 duration-700 toxicSkills invisible overflow-x-hidden translate-x-[15rem] scale-75 flex flex-col items-start  relative" id="Skills"
            >
                <h2 className="text-[3.5rem] relative font-MontBold max-lg:text-[3rem] text-[#d61d64] after:left-0 after:absolute
            after:bg-gradient-to-r after:from-[#d61d64] after:to-fuchsia-500 after:w-1/2 after:h-[2px] mx-auto py-5 text-center
             after:bottom-0">Skill-Set <span className="text-[2rem]">&</span> Tools</h2>

                <div className="flex items-center justify-center gap-6 gap-y-12 w-full mt-[2rem] py-[3rem] flex-wrap ">
                    {
                        skills.map(i => {
                            return (
                                <div className={`rounded-md shadow-2xl shadow-black/10 flex items-center justify-center flex-col overflow-hidden
                                ${!creatorContext.themeLight && "border-[.5px]"}
                                relative after:absolute after:-z-10 z-10 ${creatorContext.themeLight ? "after:bg-slate-900" : "after:bg-white"} transition-all ${creatorContext.themeLight ? "hover:text-white" : "hover:text-slate-900"} max-[500px]:hover:scale-[1] hover:scale-[1.1] hover:-translate-y-5
                                after:bottom-0 after:left-0 after:h-full after:w-0 hover:after:w-full after:transition-all    
                                ${!creatorContext.themeLight && "border-slate-800/30"} ${creatorContext.themeLight && "bg-white/40"} p-6 py-[3.5rem] min-w-[280px] max-w-[280px]`}>
                                    <div className='text-[#d61d64] '>
                                        <img src={i.icon}
                                            className='w-[50px] h-[50px] rounded-full shadow-2xl shadow-black/20 object-cover' />
                                    </div>
                                    <div className="mt-7">
                                        <h3 className="font-MontSemiBold">
                                            {
                                                i.name
                                            }
                                        </h3>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </section>
        </div>
    )
}

export default Skills