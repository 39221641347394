import React from 'react'
import { BoxPercentage } from '../CustomStyles/BoxPercentage'

import IMAGE1 from "../imgs/5.jpeg"
import IMAGE2 from "../imgs/6.jpeg"
import IMAGE3 from "../imgs/4.jpeg"
import IMAGE4 from "../imgs/3.jpeg"
import IMAGE5 from "../imgs/2.jpeg"
import IMAGE6 from "../imgs/1.jpeg"
import IMAGE7 from "../imgs/7.jpg"
import IMAGE8 from "../imgs/8.png"
import IMAGE9 from "../imgs/9.png"
import IMAGE10 from "../imgs/10.png"
import IMAGE11 from "../imgs/11.png"
import IMAGE12 from "../imgs/12.png"
import IMAGE13 from "../imgs/13.jpg"
import { motion } from "framer-motion";
import { IoArrowForward, IoChevronForward, IoHandRight, IoPersonCircle } from 'react-icons/io5'
import { RootCreatorContext } from '../contexts'
import useAnimateDragHorizontal from '../utils/hooks/useAnimateDragHorizontal'

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

const Portfolio = () => {

    const [selectedFilter, setSelectedFilter] = React.useState("All");

    const creatorContext = React.useContext(RootCreatorContext)

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 500
    };

    const services = [
        {
            variant: "Mobile",
            img: IMAGE13,
            link: null,
            links: [
                { type: "android", link: "exp://u.expo.dev/update/92a908a0-6a45-495c-9e6f-b7cb1bd2846c" },
                { type: "ios", link: "exp://u.expo.dev/update/fbe8fae1-4d52-46c6-8653-65319761fbb6" },
            ],
            text: "Linkedin Mobile App clone. This project was carried out from Front-End to Back-End, demonstrating the full potential of Native React Apps with the latest version of their SDK (SDK 49 and Expo Router). To test the you should have app Expo installed on your phone and scan both QR code link for Android/Ios.",
        },
        {
            link: "https://my-messenger-clone.vercel.app/",
            img: IMAGE10,
            variant: "Web",
            text: "Messenger clone App done from scratch from frontend part to backend part. End the design is inspired from dribbble. For testing the app you can use theses accounts like email and password :(abc@gmail.com, - abcd5@gmail.com)",
        },
        {
            link: "https://fullio-dep.web.app/",
            img: IMAGE1,
            variant: "Web",
            text: "NFT Marketplace Projects build from scratch from Front-End part to Back-End part. This app was built to take somes expectations in doing Front-End and Back-End in same time in a project.",
        },
        {
            link: "https://illuvium-v1-clone.web.app/",
            img: IMAGE2,
            variant: "Web",
            text: "V1 Illuvim Web Site cloned .Who is an open-world exploration, NFT creature collector and autobattlergame built on the Etherum Blockchain, releasing on PC and Mac in 2022",
        },
        {
            variant: "Mobile",
            img: IMAGE6,
            link: "https://drive.google.com/file/d/1MCtaBV00a7jEgd18bj-pdYyIOn0_Lu4W/view?pli=1",
            text: "First Version of Automate Future Mobile App done.Now the last version of this app is available on Store Google Play Store and App Store With name of: Silver Mirror by futures",
        },
        {
            variant: "Mobile",
            img: IMAGE7,
            link: null,
            links: [
                { type: "android", link: "exp://u.expo.dev/update/d495d4c9-e938-4435-b7b8-cd99e6dad19b" },
                { type: "ios", link: "exp://u.expo.dev/update/99fef221-ccf2-477c-affd-789311361459" },
            ],
            text: "Mobile Version of Fullio NFT Marketplace that is shown on top. To test the you should have app Expo installed on your phone and scan both QR code link for Android/Ios. For creation account you can create from the Web version (https://fullio-dep.web.app/) of the app and use the credentials to login on the Mobile app.",
        },
        {
            link: "https://awolio.vercel.app/",
            img: IMAGE8,
            variant: "Web",
            text: "Awolio...Dribbble Design Inspired",
        },
        {
            link: "https://modern-archi.vercel.app/",
            img: IMAGE9,
            variant: "Web",
            text: "Modern Architecture...Dribbble Design Inspired.",
        },
        {
            link: "https://www.figma.com/file/AimGCobHkeheU2CqiJqrfO/Untitled?type=design&node-id=0%3A1&mode=design&t=UKBuH2NlBGRMFZAy-1",
            img: IMAGE11,
            variant: "DesignUX",
            text: "My first design UI/UX implementation",
        },
        {
            link: "https://www.figma.com/file/3xEoNPrZYfTqc51zydEKAh/Uniland?type=design&node-id=0-1&mode=design",
            img: IMAGE12,
            variant: "DesignUX",
            text: "UI/UX design for awesome search for interior/properties from everywhere.",
        },
        {
            link: "https://bpci-alerte.web.app/",
            img: IMAGE3,
            variant: "Web",
            text: "Platform of escalations and alerts of the Banque Populaire de Côte-d'Ivoire. This small Website was created so that customers of the Banque Populaire de Côte-d'Ivoire can alert the Bank to certain noticed, flaws and other...",
        },
        {
            link: "https://www.youtube.com/watch?v=rQQ-g4TjyCc&t=117s",
            img: IMAGE5,
            variant: "Mobile",
            text: "Demo of first Part of Mobile App Done In Bootcamp perid on Institut Cerco. This app was built for test experience Projects.",
        },
        // {
        //     variant: "Mobile",
        //     img:IMAGE6,
        //     link: "https://drive.google.com/file/d/1MCtaBV00a7jEgd18bj-pdYyIOn0_Lu4W/view?pli=1",
        //     text: "First Version of Automate Future Mobile App done.Now the last version of this app is available on Store Google Play Store and App Store With name of: Silver Mirror by futures",
        // },
    ]


    useAnimateDragHorizontal("#folio-carousel")

    React.useEffect(() => {
        function animatedTimelines() {

            let options = {
                root: null,
                rootMargin: "0px",
                // threshold: .5,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visibleA")
                    }
                });
            }, options);

            let animateAbout = document.querySelector(".toxicFolio")
            observer.observe(animateAbout);
        }

        window.addEventListener("scroll", animatedTimelines)

        return () => {
            window.removeEventListener('scroll', animatedTimelines);
        };
    }, [])

    return (
        <section
            className="py-5 mt-[5rem] transition-all delay-1000 duration-700 toxicFolio invisible -translate-y-[15rem]  w-full" id="Portfolio"
        >
            <h2 className="text-[3.5rem] relative w-fit font-MontBold max-lg:text-[3rem] text-[#d61d64] after:left-0 after:absolute
            after:bg-gradient-to-r after:from-[#d61d64] after:to-fuchsia-500 after:w-1/2 after:h-[2px] mx-auto py-5 text-center
             after:bottom-0">Portfolio</h2>

            <div className="mt-[2.8rem] w-full">

                <p className="text-[.8rem] underline text-center font-MontBold mb-4">
                    Scroll horizontally on projects to see all
                </p>

                <p className="text-[.95rem] text-center">
                    There you can find projects done and that I can share with you.
                </p>

                <p className="text-[1.1rem] text-center">
                    Other much more private projects have been done but I can't share.
                </p>
            </div>

            {/* <div className="slider">
                <div className="slider-container">

                    
                </div>
            </div> */}

            <div className="w-3/4 mx-auto max-md:w-full flex border-[#d61d64] border-b-[1px] py-6 mb-10 gap-4
                    justify-center items-center flex-wrap">
                <button onClick={() => setSelectedFilter("All")}
                    className={`rounded-md p-6 py-2 shadow-md border-[2px] whitespace-nowrap border-[#d61d64] ${selectedFilter === "All" ? 'bg-[#d61d64]' : 'bg-transparent'} ${selectedFilter === "All" ? 'text-white' : 'text-[#d61d64]'} font-MontSemiBold text-[.8rem] transition-all`}>All</button>
                <button onClick={() => setSelectedFilter("Web")}
                    className={`rounded-md p-6 py-2 shadow-md border-[2px] whitespace-nowrap border-[#d61d64] ${selectedFilter === "Web" ? 'bg-[#d61d64]' : 'bg-transparent'} ${selectedFilter === "Web" ? 'text-white' : 'text-[#d61d64]'} font-MontSemiBold text-[.8rem] transition-all`}>Web Apps</button>
                <button
                    onClick={() => setSelectedFilter("Mobile")}
                    className={`rounded-md p-6 py-2 shadow-md border-[2px] whitespace-nowrap border-[#d61d64] ${selectedFilter === "Mobile" ? 'bg-[#d61d64]' : 'bg-transparent'} ${selectedFilter === "Mobile" ? 'text-white' : 'text-[#d61d64]'} font-MontSemiBold text-[.8rem] transition-all`}>Mobile Apps</button>

                <button
                    onClick={() => setSelectedFilter("DesignUX")}
                    className={`rounded-md p-6 py-2 shadow-md border-[2px] whitespace-nowrap border-[#d61d64] ${selectedFilter === "DesignUX" ? 'bg-[#d61d64]' : 'bg-transparent'} ${selectedFilter === "DesignUX" ? 'text-white' : 'text-[#d61d64]'} font-MontSemiBold text-[.8rem] transition-all`}>Design UX/UI</button>

            </div>

            <div id='folio-carousel' className="overflow-x-auto items-start pt-10 w-full flex max-[500px]:gap-[1rem] gap-[2rem] mt-[1rem]">
                {services.filter(sv => (sv.variant.includes(selectedFilter)) || (selectedFilter === "All")).map((e, idX) => {
                    return (
                        <div
                            className={`rounded-md after:rounded-md shadow-2xl shadow-black/10 max-[400px]:min-w-[290px] max-[400px]:max-w-[290px]  min-w-[300px] max-w-[305px] ${creatorContext.themeLight ? "text-slate-900" : "text-slate-400"} max-[450px]:w-[95%] ${creatorContext.themeLight ? "bg-white/30" : "bg-black/30"} overflow-hidden relative after:absolute after:-z-10 z-10 ${creatorContext.themeLight ? "after:bg-slate-900 hover:text-white" : "after:bg-white hover:text-slate-900"} transition-all hover:scale-[1.05] hover:-translate-y-5 
                                after:bottom-0 after:left-0 after:w-full after:h-0 hover:after:h-full after:transition-all`}>
                            <figure className={`w-full bg-black h-[250px] relative shadow-lg`}>
                                <img
                                    // controls 
                                    src={e.img}
                                    className=' object-contain absolute inset-0 h-full w-full' />
                            </figure>
                            {/* <div className="w-full p-4">
                                    <IoIosApps
                                        size={45}
                                    />
                                </div> */}
                            <div className="p-4 pt-6 pb-5">
                                <p className='text-[.8rem] leading-[1.4rem]'>{e.text}</p>

                                <div className="flex items-center flex-wrap gap-1 mt-5">
                                    {
                                        e.link && <a
                                            href={e.link}
                                            target={"_blank"}
                                            className="py-[.15rem] rounded-md px-2 transition-all hover:bg-[#d61d64] bg-transparent hover:text-white border-[1px] border-[#d61d64] gap-1 flex items-center
                        active:scale-90 text-[.95rem] text-[#d61d64] font-MontRegular shadow-lg float-left">
                                            Visit here <IoArrowForward
                                                size={15}
                                            />
                                        </a>
                                    }
                                    {
                                        e.links?.length > 0 && e.links?.map(it => {
                                            return <a
                                                href={it.link}
                                                target={"_blank"}
                                                className="py-[.15rem] rounded-md px-2 transition-all hover:bg-[#d61d64] bg-transparent hover:text-white border-[1px] border-[#d61d64] gap-1 flex items-center
                                active:scale-90 text-[.95rem] text-[#d61d64] font-MontRegular shadow-lg float-left">
                                                Visit {it.type.toUpperCase()} <IoArrowForward
                                                    size={15}
                                                />
                                            </a>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="w-wrapped"></div>
            </div>
        </section>
    )
}

export default Portfolio






