import React, { useRef } from 'react'
import { ImLinkedin } from 'react-icons/im'
import { IoLogoWhatsapp, IoMail, IoPersonCircle, IoSend } from 'react-icons/io5'
import { RiFacebookCircleLine } from 'react-icons/ri'
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import { isEmail } from '../utils/IsEmail';
import { RootCreatorContext } from '../contexts';

const Footer = () => {
    const creatorContext = React.useContext(RootCreatorContext)

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        let inputMailField = document.querySelector("#subscription-field").value


        isEmail(inputMailField) && emailjs.sendForm('service_q5b1c8z', 'template_f135rld',
            form.current, "uxPdLBSgUn4IcnoOu")
            .then((result) => {
                toast.success('Email sent successfully! Thanks.')
                document.querySelector("#subscription-field").value = ""
            }, (error) => {
                toast.error('Oops! \n An error occurred. Please try again later')
                document.querySelector("#subscription-field").value = ""
            });

        !isEmail(inputMailField) && toast.error("Le mail n'est pas valide")

    };

    return (
        <section
            id='Contacts'
            className={`w-full flex justify-between items-center flex-col max-sm:rounded-b-[0]
            gap-2 p-[4rem] mt-[8rem] rounded-b-lg backdrop-blur-sm ${creatorContext.themeLight ? "bg-white/30" : "bg-black/30"}`}>
            <div className="fixed-w flex gap-5 w-full max-lg:justify-center justify-around items-center flex-wrap">

                <div className="text-center">
                    <p className="mx-auto">Socials Media</p>
                    <div className="flex w-fit gap-[1.5rem] mt-5 items-center justify-around">
                        <a href='https://web.facebook.com/jean.avodagbe.96?mibextid=LQQJ4d&_rdc=1&_rdr' target={"_blank"} className={`active:scale-[.8] ${creatorContext.themeLight ? "text-slate-900" : "text-white"}
                            rounded-full p-[.7rem] shadow-2xl shadow-black/10 transition-all`}>
                            <RiFacebookCircleLine
                                size={22}
                            />
                        </a>
                        <a href='https://www.linkedin.com/in/jean-avodagb%C3%A9-0418661a5' target={"_blank"} className={`active:scale-[.8] ${creatorContext.themeLight ? "text-slate-900" : "text-white"}
                            rounded-full p-[.7rem] shadow-2xl shadow-black/10 transition-all`}>
                            <ImLinkedin
                                size={22}
                            />
                        </a>
                        <a href='https://wa.me/53239294?text=Hey%20there.%3F' target={"_blank"} className={`active:scale-[.8] ${creatorContext.themeLight ? "text-slate-900" : "text-white"}
                            rounded-full p-[.7rem] shadow-2xl shadow-black/10 transition-all`}>
                            <IoLogoWhatsapp
                                size={22}
                            />
                        </a>
                    </div>
                </div>

                <div className="flex flex-col gap-5">

                    <div className={`py-2 transition-all ${creatorContext.themeLight ? "hover:bg-slate-900 hover:text-white" : "hover:bg-white hover:text-slate-900"} bg-transparent  gap-4 flex items-center
        px-[2rem] justify-center text-[.95rem] ${creatorContext.themeLight ? "text-slate-900" : "text-white"} font-MontRegular rounded-sm shadow-lg`}>
                        (+229) 53-23-92-94 <IoPersonCircle
                            size={18}
                        />
                    </div>
                    <a href="mailto:avodagbejean@gmail.com" className={`py-2 transition-all ${creatorContext.themeLight ? "hover:bg-slate-900 hover:text-white" : "hover:bg-white hover:text-slate-900"} bg-transparent  gap-4 flex items-center
        px-[2rem] justify-center text-[.95rem] ${creatorContext.themeLight ? "text-slate-900" : "text-white"} font-MontRegular rounded-sm shadow-lg`}>
                        avodagbejean@gmail.com <IoMail
                            size={18}
                        />
                    </a>
                </div>

                {/* <div className="">
                    <p className="text-center w-full mb-3">Contact Me</p>
                    <form ref={form} onSubmit={sendEmail}
                        className={`flex h-[35px] min-w-[200px] w-full relative max-w-[300px]
                        border-[1px] ${creatorContext.themeLight ? "border-slate-900" : "border-white"} rounded-md overflow-hidden`}
                    >
                        <input type="email" id='subscription-field'
                            required placeholder='Enter your mail' className={`bg-transparent px-5 outline-none ${creatorContext.themeLight?"text-slate-900": "text-slate-400"} pr-0 text-[.9rem] w-full`} name="user_email" />
                        
                        <button type='submit' className={`h-full ${creatorContext.themeLight ? "bg-slate-900 text-white": "bg-white text-slate-900"} float-right ml-2 px-5 flex items-center justify-center gap-2 whitespace-nowrap`}>
                            Send <IoMail
                                size={18}
                            />
                        </button>
                    </form>
                </div> */}
            </div>
            <p className={`text-sm mx-auto text-center ${creatorContext.themeLight ? "text-slate-900" : "text-white"} mt-16`}><h3 className='text-4xl font-MontBold'>Folio.<span className='text-[#d61d64] text-xl'>JG</span></h3>@avodagbejeangontran@ All right reserved</p>
        </section>
    )
}

export default Footer